import { useRef, useState } from 'react';
import styles from './Company.module.css';
import { Badge, DatePicker, Form, Input, Select } from 'antd';
import CustomAvatar from 'components/UI/Avatar/Avatar';
import { acceptedMimes } from 'constants/mimes';
import { PencilIcon } from 'assets/icons';
import AutoCompleteTextInput from 'components/UI/AutoCompleteTextInput';
import fiddyStates from 'constants/fiddyStates';

import LegacyAPI from 'api';
import formRules from 'constants/formRules';
import moment from 'moment';
import ImageCropModal from 'components/ImageCropModal';
import { companyType } from '../Users/reportFilters';

function CompanyEdit({ form, companyData, updateCompanyData, additionalUpdate }) {
  function defaultValues() {
    if (companyData) {
      const data = JSON.parse(JSON.stringify(companyData));
      data.state = companyData.state?.code;
      if (companyData.account_create_date)
        data.account_create_date = moment(companyData.account_create_date, 'DD MMM YYYY');
      if (companyData.referral_date) data.referral_date = moment(companyData.referral_date, 'DD MMM YYYY');
      delete data.admin;
      delete data.company_creator;
      delete data.logo;
      delete data.total_members;

      return data;
    }
  }

  const uploadRef = useRef(null);

  function setAddress(city, state, zip) {
    form.setFieldsValue({ city, state, zip });
  }

  async function saveCompanyDataApi(data) {
    const formData = new FormData();

    formData.append('name', data.name || '');
    formData.append('type', data.type || '');
    formData.append('city', data.city || '');
    formData.append('state', data.state || '');
    formData.append('zip', data.zip || '');
    formData.append('address_street1', data.address_street1 || '');
    formData.append('address_street2', data.address_street2 || '');
    formData.append('website', data.website || '');
    if (data.account_create_date)
      formData.append('account_create_date', moment(data.account_create_date).format('DD/MM/YYYY'));
    formData.append('account_owner', data.account_owner || '');
    formData.append('referral_email', data.referral_email || '');
    if (data.referral_date) formData.append('referral_date', moment(data.referral_date).format('DD/MM/YYYY'));
    formData.append('referral_phone', data.referral_phone || '');
    formData.append('referral_name', data.referral_name || '');
    formData.append('id', defaultValues().id);
    pic.file && formData.append('logo', pic.file);

    try {
      await LegacyAPI().post('/user_settings/company/profile', formData);
      await updateCompanyData();
      additionalUpdate && (await additionalUpdate());
    } catch (e) {
      console.log(e);
    }
  }

  const [avatarCropModal, setAvatarCropModal] = useState({ visible: false, file: null });

  function handleCroppedPhoto(blob, dataURL) {
    setPic({
      file: blob,
      thumb: dataURL,
    });

    setAvatarCropModal((prev) => ({ ...prev, visible: false }));
  }

  const [pic, setPic] = useState({ file: null, thumb: null });

  function handleSelectPic() {
    setAvatarCropModal({ visible: true, file: uploadRef.current.files[0] });
  }

  function resetFileInput() {
    uploadRef.current.value = null;
  }

  return (
    <div>
      <Form
        className={styles.form}
        form={form}
        initialValues={defaultValues()}
        layout="vertical"
        onFinish={(values) => {
          console.log(values);

          saveCompanyDataApi(values);
        }}
        requiredMark={false}
      >
        <div className={styles.p}>
          <div className={styles.avatarWrapper}>
            <input
              hidden
              type="file"
              ref={uploadRef}
              accept={acceptedMimes.avatar}
              onChange={handleSelectPic}
              onClick={resetFileInput}
            />
            <Badge count={<PencilIcon colour="white" />} onClick={() => uploadRef.current?.click()}>
              <CustomAvatar name={companyData.name} src={pic.thumb || companyData.logo} size={72} />
            </Badge>
          </div>
          <div>
            <p className={styles.usernameTitle}>{defaultValues().name}</p>
            <p className={styles.subTitle} style={{ marginBottom: 8 }}>
              Created: 12 May 2024
            </p>
          </div>
        </div>
        <div className={styles.containerWithoutPBottom}>
          <Form.Item label="Company name" name="name" className={styles.formItem}>
            <Input />
          </Form.Item>
          <Form.Item label="Created" name="account_create_date" className={styles.formItem}>
            <DatePicker picker="date" style={{ width: '100%' }} />
          </Form.Item>
          <div className={styles.divider} />
        </div>
        <div className={styles.containerWithoutPTop}>
          <Form.Item label="Account Owner" name="account_owner" className={styles.formItem}>
            <Input />
          </Form.Item>
          <Form.Item label="Referred by:" name="referral_name" className={styles.formItem}>
            <Input />
          </Form.Item>
          <Form.Item label="Referral email:" name="referral_email" className={styles.formItem}>
            <Input />
          </Form.Item>
          <Form.Item label="Referral date:" name="referral_date" className={styles.formItem}>
            <DatePicker picker="date" style={{ width: '100%' }} />
          </Form.Item>
          <Form.Item
            className={styles.formItem}
            label="Referral phone:"
            name="referral_phone"
            validateTrigger="onSubmit"
            rules={[{ ...formRules.phone, required: true }]}
          >
            <Input addonBefore="+1" maxLength={10} />
          </Form.Item>
        </div>
        <div className={styles.sectionAdditionalInfo}>
          <p className={styles.titleUpperCase}>Profile details:</p>
          <Form.Item label="address 1:" name="address_street1" className={styles.formItem}>
            <Input />
          </Form.Item>
          <Form.Item label="address 2:" name="address_street2" className={styles.formItem}>
            <Input />
          </Form.Item>
          <Form.Item label="Company type" name="type" rules={[{ required: true }]} className={styles.formItem}>
            <Select options={companyType.map((value) => ({ value }))} placeholder="company type" />
          </Form.Item>
          <Form.Item
            className={styles.wideRow}
            label="Company website"
            name="website"
            rules={[
              {
                transform: (uri) => (uri ? `https://${uri}` : uri),
                type: 'url',
              },
            ]}
          >
            <Input addonBefore="https://" placeholder="www.example.com" />
          </Form.Item>
          <div style={{ flexDirection: 'row' }}>
            <Form.Item label="City" name="city" className={styles.formItem}>
              <AutoCompleteTextInput
                removeCustomStyle
                setSelectedAddress={(_, city, { value: state }, zip) => setAddress(city, state, zip)}
              />
            </Form.Item>
          </div>
          <Form.Item label="State" name="state" className={styles.formItem}>
            <Select options={fiddyStates.map((value) => ({ value }))} />
          </Form.Item>
          <Form.Item
            label="Zip"
            name="zip"
            rules={[
              {
                len: 5,
                pattern: /^[0-9]+$/,
                type: 'string',
              },
            ]}
          >
            <Input maxLength={5} />
          </Form.Item>
        </div>
        <ImageCropModal
          file={avatarCropModal.file}
          format="avatar"
          onCancel={() => setAvatarCropModal((prev) => ({ ...prev, visible: false }))}
          onOk={handleCroppedPhoto}
          visible={avatarCropModal.visible}
          title="Adjust Your Picture"
        />
      </Form>
    </div>
  );
}

export default CompanyEdit;
