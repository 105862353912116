import { Card, Col, Divider, Row, Typography } from 'antd';
import { valueToLocaleString } from 'utils/format-to-locale-string';

export default function LoanProfileSourcesAndUses({
  isRenovationOrConstruction,
  isPurchase,
  isRefinance,
  data = {},
  refinance = {},
  isPurchaseAndRenovationExists,
}) {
  return (
    <Card>
      <Typography.Title level={4}>Sources and Uses</Typography.Title>
      <Divider style={{ margin: '0 0 16px 0' }} />
      <Row gutter={[24, 24]}>
        <Col span={12}>
          <Row gutter={[8, 8]}>
            <Col span={24}>
              <Typography.Text strong>Sources</Typography.Text>
            </Col>
            <Col span={24}>
              <Row justify="space-between">
                <Col>
                  <Typography.Text type="secondary">Loan Amount Requested</Typography.Text>
                </Col>
                <Col>{valueToLocaleString(data.loan_amount)}</Col>
              </Row>
            </Col>
            {isRenovationOrConstruction && (
              <Col span={24}>
                <Row justify="space-between">
                  <Col>
                    <Typography.Text type="secondary">Land Value (Borrower Contributed)</Typography.Text>
                  </Col>
                  <Col>{valueToLocaleString(data.land_value_borrower_contributed)}</Col>
                </Row>
              </Col>
            )}
            <Col span={24}>
              <Row justify="space-between">
                <Col>
                  <Typography.Text type="secondary">Borrower Equity</Typography.Text>
                </Col>
                <Col>{valueToLocaleString(data.borrower_equity)}</Col>
              </Row>
            </Col>
            <Col span={24}>
              <Row justify="space-between">
                <Col>
                  <Typography.Text type="secondary">Additional Capital Sources</Typography.Text>
                </Col>
                <Col>{valueToLocaleString(data.additional_capital_sources)}</Col>
              </Row>
            </Col>
            <Col span={24}>
              <Divider style={{ margin: '0' }} />
            </Col>
            <Col span={24}>
              <Row justify="space-between">
                <Col>
                  <Typography.Text type="secondary">Total Sources</Typography.Text>
                </Col>
                <Col>{valueToLocaleString(data.total_sources)}</Col>
              </Row>
            </Col>
          </Row>
        </Col>
        <Col span={12}>
          <Row gutter={[8, 8]}>
            <Col span={24}>
              <Typography.Text strong>Uses</Typography.Text>
            </Col>
            {(isRenovationOrConstruction || isRefinance) && (
              <Col span={24}>
                <Row justify="space-between">
                  <Col>
                    <Typography.Text type="secondary">Construction/Reno Budget</Typography.Text>
                  </Col>
                  <Col>{valueToLocaleString(data.construction_reno_budget)}</Col>
                </Row>
              </Col>
            )}
            {isRenovationOrConstruction && (
              <>
                <Col span={24}>
                  <Row justify="space-between">
                    <Col>
                      <Typography.Text type="secondary">Land Cost</Typography.Text>
                    </Col>
                    <Col>{valueToLocaleString(data.land_cost)}</Col>
                  </Row>
                </Col>
                <Col span={24}>
                  <Row justify="space-between">
                    <Col>
                      <Typography.Text type="secondary">Existing Structure Purchase Price</Typography.Text>
                    </Col>
                    <Col>
                      {data.existing_structure_purchase_price &&
                        valueToLocaleString(data.existing_structure_purchase_price)}
                    </Col>
                  </Row>
                </Col>
              </>
            )}
            {isRefinance ? (
              <>
                <Col span={24}>
                  <Row justify="space-between">
                    <Col>
                      <Typography.Text type="secondary">Morgage Payoff</Typography.Text>
                    </Col>
                    <Col>{valueToLocaleString(data.mortgage_payoff)}</Col>
                  </Row>
                </Col>
                <Col span={24}>
                  <Row justify="space-between">
                    <Col>
                      <Typography.Text type="secondary">Cash-out Request</Typography.Text>
                    </Col>
                    <Col>{data.cash_out && valueToLocaleString(data.cash_out)}</Col>
                  </Row>
                </Col>
              </>
            ) : (
              <Col span={24}>
                <Row justify="space-between">
                  <Col>
                    <Typography.Text type="secondary">Purchase Price</Typography.Text>
                  </Col>
                  <Col>{valueToLocaleString(data.purchase_price)}</Col>
                </Row>
              </Col>
            )}
            <Col span={24}>
              <Row justify="space-between">
                <Col>
                  <Typography.Text type="secondary">Closing Costs</Typography.Text>
                </Col>
                <Col>{valueToLocaleString(data.closing_costs)}</Col>
              </Row>
            </Col>
            <Col span={24}>
              <Divider style={{ margin: '0' }} />
            </Col>
            <Col span={24}>
              <Row justify="space-between">
                <Col>
                  <Typography.Text type="secondary">Total Uses</Typography.Text>
                </Col>
                <Col>{valueToLocaleString(data.total_uses)}</Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
      <Divider />
      <Row gutter={[16, 16]}>
        <Col span={12}>
          <Row gutter={[16, 8]}>
            {isRenovationOrConstruction && (
              <>
                <Col span={24}>
                  <Row gutter={[16, 8]} justify="space-between">
                    <Col>
                      <Typography.Text type="secondary">ARV</Typography.Text>
                    </Col>
                    <Col>{valueToLocaleString(data.arv)}</Col>
                  </Row>
                </Col>
                <Col span={24}>
                  <Row gutter={[16, 8]} justify="space-between">
                    <Col>
                      <Typography.Text type="secondary">LTV (Based on ARV)</Typography.Text>
                    </Col>
                    <Col>{data.ltv ? `${Math.round(data.ltv * 100)}%` : ''}</Col>
                  </Row>
                </Col>
                <Col span={24}>
                  <Row gutter={[16, 8]} justify="space-between">
                    <Col>
                      <Typography.Text type="secondary">LTC (Based on Total Uses)</Typography.Text>
                    </Col>
                    <Col>{data.ltc ? `${Math.round(data.ltc * 100)}%` : ''}</Col>
                  </Row>
                </Col>
              </>
            )}
            {isPurchase && (
              <>
                <Col span={24}>
                  <Row gutter={[16, 8]} justify="space-between">
                    <Col>
                      <Typography.Text type="secondary">LTV (Based on Purchase Price)</Typography.Text>
                    </Col>
                    <Col>{data.ltv ? `${Math.round(data.ltv * 100)}%` : ''}</Col>
                  </Row>
                </Col>
                <Col span={24}>
                  <Row gutter={[16, 8]} justify="space-between">
                    <Col>
                      <Typography.Text type="secondary">LTC (Based on Total Uses)</Typography.Text>
                    </Col>
                    <Col>{data.ltc ? `${Math.round(data.ltc * 100)}%` : ''}</Col>
                  </Row>
                </Col>
              </>
            )}
            {isRefinance && (
              <Col span={24}>
                <Row gutter={[16, 8]} justify="space-between">
                  <Col>
                    <Typography.Text type="secondary">LTV (Based on Purchase Price)</Typography.Text>
                  </Col>
                  <Col>{data.ltv ? `${Math.round(data.ltv * 100)}%` : ''}</Col>
                </Row>
              </Col>
            )}
            {(refinance?.refinance_goals === 'Cash out - max possible' ||
              isRefinance ||
              isPurchaseAndRenovationExists) && (
              <Col span={24}>
                <Typography.Paragraph style={{ margin: '1.5rem 0' }}>
                  NOTE: The Borrower is requesting to maximize cash-out proceeds. Therefore, these Source and Use
                  calculations are not correct. They need to be adjusted to include the cash out amount.
                </Typography.Paragraph>
              </Col>
            )}
          </Row>
        </Col>
      </Row>
      {!isRefinance && <Divider />}
      <Typography.Text strong style={{ display: 'block', margin: '20px 0 8px' }}>
        Loan Request Details
      </Typography.Text>
      <Row gutter={[24, 24]}>
        <Col span={12}>
          <Row gutter={[8, 8]}>
            <Col span={24}>
              <Row justify="space-between">
                <Col>
                  <Typography.Text type="secondary">Interest Rate type</Typography.Text>
                </Col>
                <Col>{data.interest_rate_type?.join(',')}</Col>
              </Row>
            </Col>
            <Col span={24}>
              <Row justify="space-between">
                <Col>
                  <Typography.Text type="secondary">Payment Type</Typography.Text>
                </Col>
                <Col>{data.payment_type?.join(',')}</Col>
              </Row>
            </Col>
            <Col span={24}>
              <Row justify="space-between">
                <Col>
                  <Typography.Text type="secondary">Loan Terms</Typography.Text>
                </Col>
                <Col>{data.loan_terms?.join(',')}</Col>
              </Row>
            </Col>
          </Row>
        </Col>
        <Col span={12}>
          <Row gutter={[8, 8]}>
            <Col span={24}>
              <Row justify="space-between">
                <Col>
                  <Typography.Text type="secondary">Pre Payment</Typography.Text>
                </Col>
                <Col>{data.prepayment?.join(',')}</Col>
              </Row>
            </Col>
            <Col span={24}>
              <Row justify="space-between">
                <Col>
                  <Typography.Text type="secondary">Recourse</Typography.Text>
                </Col>
                <Col>{data.recourse?.join(',')}</Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
    </Card>
  );
}
