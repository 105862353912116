import { Badge, Form, Input } from 'antd';
import CustomAvatar from 'components/UI/Avatar/Avatar';
// import CustomButton from 'components/UI/Button/Button';
import { acceptedMimes } from 'constants/mimes';
import { useRef, useState } from 'react';
import formRules from 'constants/formRules';

import styles from './UserDrawer.module.css';
import { PencilIcon } from 'assets/icons';
// import { SettingsAPI } from 'api';
import ImageCropModal from 'components/ImageCropModal';
import API from 'api';

function UserDrawerEdit({ form, user, fetchGetUser }) {
  const uploadRef = useRef(null);

  const [avatarCropModal, setAvatarCropModal] = useState({ visible: false, file: null });

  function handleCroppedPhoto(blob, dataURL) {
    setPic({
      file: blob,
      thumb: dataURL,
    });

    setAvatarCropModal((prev) => ({ ...prev, visible: false }));
  }

  const [pic, setPic] = useState({ file: null, thumb: null });

  async function updateProfileInfo(values) {
    // console.log(values, user);
    const formData = new FormData();

    formData.append('email', values.email);
    formData.append('name', values.name);
    formData.append('phone', values.phone);
    formData.append('surname', values.surname);
    user.id && formData.append('user_id', user.id);
    pic.file && formData.append('profile_photo', pic.file);
    values.work_phone && formData.append('work_phone', values.work_phone);

    await API()
      .post('/profile', formData)
      .then((res) => res?.data);

    await fetchGetUser();
  }

  function handleSelectPic() {
    setAvatarCropModal({ visible: true, file: uploadRef.current.files[0] });
  }

  function resetFileInput() {
    uploadRef.current.value = null;
  }

  return (
    <div>
      <Form
        className={styles.form}
        form={form}
        initialValues={{
          name: user.name,
          surname: user.surname,
          email: user.email,
          phone: user.phone,
          work_phone: user.work_phone,
        }}
        layout="vertical"
        onFinish={async (values) => {
          updateProfileInfo(values);
        }}
        requiredMark={false}
      >
        <div className={styles.section}>
          <div className={styles.avatarWrapper}>
            <input
              hidden
              type="file"
              ref={uploadRef}
              accept={acceptedMimes.avatar}
              onChange={handleSelectPic}
              onClick={resetFileInput}
            />
            <Badge count={<PencilIcon colour="white" />} onClick={() => uploadRef.current?.click()}>
              <CustomAvatar
                name={user.name}
                src={pic.thumb || user.avatar || user.profile_photo}
                surname={user.surname}
                size={72}
              />
            </Badge>
          </div>
          <div>
            <p className={styles.usernameTitle}>{user.full_name}</p>
            <p className={styles.subTitle} style={{ marginBottom: 8 }}>
              Created: 12 May 2024
            </p>
            <p className={styles.subTitle}>Broker Loan Manager</p>
            <p className={styles.subTitle}>Disabled</p>
          </div>
        </div>
        <div className={styles.section}>
          <Form.Item label="Name" name="name" rules={[{ required: true, message: 'Please input your name!' }]}>
            <Input />
          </Form.Item>
          <Form.Item
            label="Last name"
            name="surname"
            rules={[{ required: true, message: 'Please input your last name!' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item label="Business email" name="email" rules={[{ ...formRules.email, required: true }]}>
            <Input />
          </Form.Item>
          <Form.Item label="Work phone" name="work_phone" rules={[formRules.phone]} validateTrigger="onSubmit">
            <Input addonBefore="+1" maxLength={10} />
          </Form.Item>
          <Form.Item
            label="Mobile phone"
            name="phone"
            validateTrigger="onSubmit"
            rules={[{ ...formRules.phone, required: true }]}
          >
            <Input addonBefore="+1" maxLength={10} />
          </Form.Item>
        </div>
        <ImageCropModal
          file={avatarCropModal.file}
          format="avatar"
          onCancel={() => setAvatarCropModal((prev) => ({ ...prev, visible: false }))}
          onOk={handleCroppedPhoto}
          visible={avatarCropModal.visible}
          title="Adjust Your Picture"
        />
      </Form>
    </div>
  );
}

export default UserDrawerEdit;
