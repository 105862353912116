import { Drawer, Form, message } from 'antd';
import { CheckOutlined } from '@ant-design/icons';
import { isEmpty } from 'lodash';
import { formatPhone } from 'constants/formatPhone';
import { replacedTextData } from 'constants/common';
import CustomAvatar from 'components/UI/Avatar';
import arrowRight from 'assets/images/arrow-right.svg';
import styles from './UserDrawer.module.css';
import CustomButton from 'components/UI/Button/Button';
import { PencilIcon } from 'assets/icons';
import { useEffect, useState } from 'react';
import UserDrawerEdit from './UserDrawerEdit';
import API from 'api';
import CompanyDrawer from 'pages/Onboarding/SuperAdmin/Companies/CompanyDrawer';
import { useSelector } from 'react-redux';

function UserDrawer({ user, visible, onClose, fetchGetUser }) {
  if (isEmpty(user)) {
    return null;
  }

  const { role } = useSelector((s) => s.user.userData);
  const isPLA = /^superadmin$|pla/i.test(role);

  const [activeModal, setActiveModal] = useState({ show: false, data: null });
  const onCloseActiveModal = () => setActiveModal((state) => ({ ...state, show: false }));
  const onOpenActiveModal = () => setActiveModal((state) => ({ ...state, show: true }));

  const [company, setCompany] = useState();
  // const lastOnline = new Date(user.last_online).toLocaleString();

  function DetailsRow({ title, value }) {
    return (
      <div className={styles.companyDetailsRowContainer}>
        <p className={styles.companyDetailsRowTitle}>{title}</p>
        <p className={styles.companyDetailsRowValue}>{value || 'No info'}</p>
      </div>
    );
  }

  async function fetchCompany() {
    const res = await API().get('/user_settings/company/profile', {
      params: {
        company_id: user.company.id,
      },
    });
    console.log(res);
    setCompany({
      loading: true,
      company_data: res.data,
    });
  }

  console.log('user', user);
  useEffect(() => {
    if (user?.company?.id) {
      fetchCompany();
    }
  }, [user?.company?.id]);

  const [isEdit, setIsEdit] = useState(false);

  const [form] = Form.useForm();

  console.log(user);

  function onResetPassword() {
    API()
      .post('/superadmin/user/reset-password', { id: user.id })
      .then(() => {
        message.success('Link sent successfully');
      });
  }
  async function showCompanyDrawer() {
    onClose();
    onOpenActiveModal();
  }

  console.log(user);
  return (
    <>
      <Drawer
        bodyStyle={{ padding: 0 }}
        className={styles.drawer}
        placement="right"
        open={visible}
        width={360}
        headerStyle={{ display: 'none' }}
      >
        <div className={styles.drawerWrapper}>
          <div className={styles.drawerHeader}>
            <img style={{ width: 24 }} onClick={onClose} src={arrowRight} alt="" />
            <p className={styles.headerTitle}>User Profile</p>
            <div
              onClick={(e) => {
                e.stopPropagation();
                setIsEdit((prev) => !prev);
              }}
              className={styles.buttonEdit}
            >
              {isPLA &&
                (isEdit ? (
                  <div
                    onClick={(e) => {
                      e.stopPropagation();
                      setIsEdit(false);
                      form.submit();
                    }}
                    className={styles.buttonEditOn}
                  >
                    <span className={styles.buttonEditOnTitle}>Save</span>
                    <CheckOutlined size={24} className={styles.buttonEditOnIcon} />
                  </div>
                ) : (
                  <PencilIcon />
                ))}
            </div>
          </div>
          {isEdit ? (
            <UserDrawerEdit fetchGetUser={fetchGetUser} form={form} user={user} />
          ) : (
            <>
              <div className={styles.section}>
                <div className={styles.drawerCompanyInfo}>
                  <CustomAvatar fullName={user.full_name} src={user.profile_photo || user.avatar} size={72} />
                  <div className={styles.drawerCompanyDetails}>
                    <p className={styles.companyDetaisTitle}>{user.full_name}</p>
                    <p className={styles.companyDetaisSubTitle}>{replacedTextData[user.role] || user.role}</p>
                    <p className={styles.companyDetaisSubTitle}>{user?.user_json?.registration_status_name}</p>
                  </div>
                </div>
              </div>
              {(user?.company?.name || user.company_name) && (
                <div className={styles.section}>
                  <p className={styles.titleUpperCase}>Company:</p>
                  <div className={styles.drawerCompanyInfo}>
                    <CustomAvatar src={user.avatar} size={56} />
                    <div className={styles.drawerCompanyDetails}>
                      <p className={styles.companyAdminTitle}>{user?.company?.name || user.company_name}</p>
                      <CustomButton className={styles.customBtn} onClick={showCompanyDrawer} text="See Details" />
                    </div>
                  </div>
                </div>
              )}
              <div className={styles.section}>
                <p className={styles.titleUpperCase}>Profile Details:</p>
                <DetailsRow title="Business email" value={user.email} />
                <div className={styles.divider} />
                <DetailsRow title="Work phone" value={formatPhone(`+1${user.work_phone}`)} />
                <div className={styles.divider} />
                <DetailsRow title="Mobile Phone" value={formatPhone(`+1${user.phone}`)} />
              </div>
              <div className={styles.containerButton}>
                <CustomButton onClick={onResetPassword} primary text="Send password reset email" />
              </div>
            </>
          )}
        </div>
      </Drawer>
      {company && (
        <CompanyDrawer
          // setShouldShowAdminModal={setShouldShowAdminModal}
          company={company}
          activeModal={activeModal}
          onCloseActiveModal={onCloseActiveModal}
          updateCompanyData={fetchCompany}
          additionalUpdate={fetchGetUser}
        />
      )}
    </>
  );
}

export default UserDrawer;
